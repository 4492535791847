@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

@font-face {
  font-family: 'Lexend Deca Black';
  src: url('./fonts/LexendDeca-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('./fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans 3';
  src: url('./fonts/SourceSans3-VariableFont_wght.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.image-container {
  position: relative; /* Establece el contexto de posición */
  display: inline-block; /* Para que el contenedor se ajuste al tamaño de la imagen */
}

.overlay-text {
  position: absolute; /* Establece posición absoluta con respecto al contenedor padre */
  top: 60%; /* Coloca el texto en la mitad superior del contenedor */
  left: 70%; /* Coloca el texto en la mitad izquierda del contenedor */
  transform: translate(-50%, -50%); /* Centra el texto en el centro del contenedor */
  color: rgb(0, 0, 0); /* Color del texto */
  padding: 10px; /* Espacio alrededor del texto */
  font-size: 20px; /* Tamaño de fuente del texto */
  width: 50%;
}
