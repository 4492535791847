* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9f3fe;
}
.parent {
  width: 450px;
  margin: auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 25px;
  box-shadow: 7px 20px 20px rgb(210, 227, 244);
}
.file-upload {
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  padding: 1.5rem;
  position: relative;
  cursor: pointer;
}
.file-upload p {
  font-size: 0.87rem;
  margin-top: 10px;
  color: #bbcada;
}
.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.upload-image {
  max-width: 100px;
}

.user-add-content {
  padding: 20px;
}

.user-add-container {
  margin-top: 50px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid #161d28;
}

/* Media query for screens with a minimum width of 768px (adjust as needed) */
@media (min-width: 768px) {
  .user-add-container {
    max-width: 30%; /* Reduce the width on larger screens */
  }
}

.add-icon {
  color: #fff; 
}

.add-icon {
  border-radius: 50px;
  color: #161d28;
}

.add-user-title {
  font-weight: bold;
  margin-top: 20px;
  color: #333;
  font-size: large;
}

.add-user-button {
  margin-top: 20px;
}

.add-icon {
  color: #fff;
}


@media screen and (max-width: 600px) {
  /* Estilos de tarjetas aquí */
  .card-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background: white;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
  }
  
  .card-content h4, .card-content p {
    margin: 5px 0;
  }
  
  .card-content button {
    align-self: center;
    margin-top: 10px;
  }
  
  
}
