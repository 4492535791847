
.dialog-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  width: 100%;
}

.document-add-container {
  margin-top: 50px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid #161d28;
  margin: 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.file-input-label {
  display: inline-block;
  cursor: pointer;
  background-color: #161d28; /* Background color set to #161d28 */
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.file-input-label:hover {
  background-color: #cc0077; /* Hover background color */
}


.icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}

.image{
  max-width: 130px;
  max-height: 80px;
}

.title-text{
  font-size: 18px !important;
}

.ring{
  width: 200px;
  height: 200px;
  border: 0px solid rgb(255, 255, 255);
  border-radius: 50%;
  position: absolute;
}

.loading-text {
  position: absolute;
  font-size: 50px;
  color: rgb(255, 255, 255);
  animation: colorChange 6s linear infinite;
}



body{
  margin: 0;
  padding: 0;
  font-family: montserrat;
  background: black;
}
.center{
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.ring{
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}
@keyframes ring {
  0%{
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #e65c00;
  }
  50%{
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #18b201;
  }
  100%{
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #0456c8;
  }
}
.ring:before{
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255,255,255,.3);
}
.span{
  color: #737373;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 200px;
  animation: text 3s ease-in-out infinite;
}
@keyframes text {
  50%{
    color: black;
  }
}