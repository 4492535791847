.container{
  width: 100%; 
  margin-top: 30px !important;
  padding: 0;
}

.card-custom{
  border-radius: 20px !important;
  max-width: 370px;
  margin: auto;
  
}

.center-item {
  margin: auto;
  text-align: center;
}

/* For screens bigger than 768px (e.g., tablets and desktops) */
@media (min-width: 768px) {
  .container {
    width: 75%; /* Or whatever width you desire for bigger screens */
    margin: 0 auto; /* This centers the container */
    padding: 0 100px;
  }
}

/* Optionally, for even larger screens (e.g., big desktop monitors) */
@media (min-width: 1200px) {
  .container {
    width: 50%; /* Adjust this value based on your design */
    padding: 0 100px;
  }
}