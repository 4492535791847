
.dialog-container {
  display: flex;
  flex-direction: column;
  min-height: 0;
  width: 100%;
}

.document-add-container {
  margin-top: 50px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid #161d28;
  margin: 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.file-input-label {
  display: inline-block;
  cursor: pointer;
  background-color: #161d28; /* Background color set to #161d28 */
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.file-input-label:hover {
  background-color: #cc0077; /* Hover background color */
}


.icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}

.image{
  max-width: 130px;
  max-height: 80px;
}

.title-text{
  font-size: 18px !important;
}

/* For screens bigger than 768px (e.g., tablets and desktops) */
@media (min-width: 768px) {
  .card-container {
    width: 100%; /* Or whatever width you desire for bigger screens */
    margin: 0 auto; /* This centers the container */
    padding: 40px 80px;
  }
}

/* Optionally, for even larger screens (e.g., big desktop monitors) */
@media (min-width: 1200px) {
  .card-container {
    width: 100%; /* Adjust this value based on your design */
    padding: 40px 80px;
  }
}
