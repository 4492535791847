.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10;
  min-width: 300px;
  width: 100%;
}

.dialog-content {
  display: flex;
  min-height: 100%;
  width: 100%;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

.button {
  width: 1000px;
  height: 110px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 2px dashed #ccc; */
  cursor: pointer;
  margin: 10px !important;
  background-color: #161d28 !important;
  
}

.icon {
  font-size: 36px;
}

.document-add-container {
  margin-top: 50px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid #161d28;
  margin: 10px;
  min-height: 200px;
}

.flex {
  display: flex;
}

.document-add-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}


.pink-button {
  background-color: #161d28;
  color: white;
}

.file-input-label {
  display: inline-block;
  cursor: pointer;
  background-color: #161d28; /* Background color set to #161d28 */
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.file-input-label:hover {
  background-color: #cc0077; /* Hover background color */
}

/* Hide the file input */
.file-input {
  display: none;
}

.icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Container for the icon */
.icon-container {
  display: flex;
  align-items: center;
  margin-right: 8px; /* Adjust the margin as needed */
  margin-top: 7px;
  margin-bottom: 10px;
}

.dialog-buttons {
   align-self: flex-end;
}

.icon-card{
  width: 25px;
  margin-bottom: 10px;  
  filter: saturate(500%) contrast(800%) brightness(500%) 
  invert(80%) sepia(50%) hue-rotate(120deg); 
}


.document-add-container-modal {
  /* display: flex; */
  flex-direction: column; /* Configuración predeterminada para móviles */
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  border: 2px solid #161d28;
  padding: 10px;
  /* Resto de tus estilos ... */
}

/* Media Queries */
@media screen and (min-width: 768px) {
  .document-add-container-modal {
    flex-direction: row; /* Alineación horizontal para pantallas más grandes */
    justify-content: center; /* Asegura que los elementos estén centrados */
  }

}

/* Resto de tus estilos ... */
