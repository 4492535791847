.card-container {
  width: 100%;
  border-radius: 30px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.141) !important;
  padding: 0;
}

.modal-container {
  width: 100%;
  padding: 0;
}

.myClass {
  font-family: 'Lexend Deca Black', sans-serif;
}

.link {
  color: rgb(188, 123, 134);
  text-decoration: underline;
  cursor: pointer;
}

/* For screens bigger than 768px (e.g., tablets and desktops) */
@media (min-width: 768px) {
  .card-container {
    width: 75%; /* Or whatever width you desire for bigger screens */
    margin: 0 auto; /* This centers the container */
    padding: 40px 80px;
  }

  .modal-container {
    width: 100%;
    padding: 0 40px;
  }
}

/* Optionally, for even larger screens (e.g., big desktop monitors) */
@media (min-width: 1200px) {
  .card-container {
    width: 50%; /* Adjust this value based on your design */
    padding: 40px 100px;
  }

  .modal-container {
    width: 100%;
    padding: 0 100px;
  }
}
